.carousel-indicators {
  bottom: -3rem;
  li {
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $color-prGreen;
    opacity: 0.3;
  }
}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
  z-index: auto;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-size: 200px;
  width: 2.5rem;
  height: 2.5rem;
}

.carousel-control-next-icon {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg); /* Standard syntax */
}

@media only screen and (max-width: 767px ) {
  .carousel-indicators {
    bottom: -2rem;
  }
}

@media only screen and (max-width: 480px ) {
  .carousel-control-prev, .carousel-control-next {
    width: 12%;
  }
}