select.form-control:focus {
  color: #001437;
}

select.form-control {
  color: #001437;
  width: 35%;
  height: 45px;
  font-weight: 700;
  font-size: 18px;
}

.wrap-drop {
  background: transparent;
  border: 1px solid $color-white;
  cursor: pointer;
  padding: 8px 15px;
  position: relative;
  border-radius: 6px;
  font-weight: 600;
  width: 100%;
  max-width: 350px;
  margin: 0 22px 10px 0;
  span {
    color: #fff;
  }
  .drop {
    background:#fff;
    box-shadow:3px 3px 3px rgba(0,0,0,.2);
    display:none;
    left:0;
    list-style:none;
    margin-top:0;
    opacity:0;
    padding-left:0;
    pointer-events:none;
    position:absolute;
    right:0;
    top: calc(100% + 3px);
    z-index:2;
    li{
      display: block;
      width: 100%;
      a, span {
        color: #5E5E5E;
        display: inline-block;
        text-decoration: none;
        font-size: 14px;
      }
      a {
        padding: 7px 15px;
      }
    }
  }
}

.wrap-drop::after {
  border-color: #fff transparent;
  border-style: solid;
  border-width: 7px 6px 0;
  content: "";
  height: 0;
  margin-top: -2px;
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 0;
}

.wrap-drop.active{
  .drop {
    display: block;
    opacity: 1;
    pointer-events: auto;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
}

@media only screen and (max-width: 767px ) {
  select.form-control {
    width: 100%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px ) {
  select.form-control {
    width: 40%;
  }
}