.benefits-list {
  margin: 0 -10px;
  &__item {
    padding: 10px;
  }
}

.benefits-slider {
  .owl-item {
    height: 460px;
    .benefits__item {
      height: 100%;
    }
  }
}

.benefit {
  background: $color-white;
  padding: 2.5rem 1.25rem 2.5rem 1.5rem;
  border-radius: 0.5rem;
  height: 100%;
  &__icon {
    height: 44px;
    img {
      max-height: 100%;
    }
  }
  h5 {
    font-size: 1.5rem;
    color: $color-lessBlack;
    line-height: 2rem;
    margin: 0.8rem 0;
    white-space: pre-line;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.benefits-list__item, .benefits-slider .owl-item {
  &:nth-of-type(4n + 1) .benefit {
    background-color: #DAE0FF;
  }
  &:nth-of-type(4n + 2) .benefit {
    background-color: #BEFFFB;
  }
  &:nth-of-type(4n + 3) .benefit {
    background-color: #DCD4D4;
  }
  &:nth-of-type(4n) .benefit {
    background-color: #FFFFFF;
  }
}

@media only screen and (max-width:480px ) {
  .benefits-slider {
    .owl-item {
      height: 330px;
    }
  }

  .benefit {
    padding: 1.5rem 1rem;
    h5 {
      white-space: initial;
      margin-top: 1rem;
    }
  }
}
