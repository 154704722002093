.chart {
  width: 1180px;
  height: 1180px;
  box-sizing: border-box;
  border-radius: 50%;
  background: conic-gradient(from 0deg, rgba(184,189,196,1) 0%, rgba(184,189,196,1) 25%, rgba(184, 189, 196, 0.1) 50%, #011536 50%, #011536 100%);
  padding: 70px;
  margin-left: -50px;
  position: relative;
  &__employees {
    position: absolute;
    top: 70px;
    right: 30px;
  }
  &__internal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #F2F2F2;
    position: relative;
    overflow: hidden;
  }
  &__left {
    width: 50%;
    background: linear-gradient(180deg, #CBD0D3 0, #CBD0D3 50%, #F2F2F2 50%, #F2F2F2 100%);
    box-sizing: border-box;
    overflow: hidden;
  }
  &__left-inner {
    width: calc(200% - 140px);
    height: calc(100% - 140px);
    background: linear-gradient(180deg, #011536 0, #011536 50%, #282767 50%, #282767 100%);
    box-sizing: border-box;
    border-radius: 50%;
    margin: 70px;
  }
  &__left-top {
    height: 50%;
    display: flex;
    flex-direction: column;
    padding: 110px 0 0 70px;
    position: relative;
  }
  &__directors {
    position: absolute;
    top: -25px;
    left: -25px;
  }
  &__left-top1 {
    padding-left: 115px;
    margin-bottom: 40px;
  }
  &__left-bottom {
    height: 50%;
    box-sizing: border-box;
    padding: 55px 0 0 70px;
    shape-margin: 0;
    position: relative;
  }
  &__leads {
    position: absolute;
    top: 100px;
    left: -27px;
    transform: rotate(-83deg);
  }
  &__right {
    width: 50%;
    overflow: hidden;
  }
  &__right-inner {
    width: 200%;
    height: 100%;
    background: conic-gradient(from 0deg, #DBE1FE 0%, #DBE1FE 12.5%, #C0FFFB 12.5%, #C0FFFB 25%, #DCD4D4 25%, #DCD4D4 37.5%, #FFFFFF 37.5%, #FFFFFF 50%, #FFFFFF 50%, #FFFFFF 100%);
    box-sizing: border-box;
    border-radius: 50%;
    padding-left: calc(100% + 30px);
    padding-top: 90px;
    margin-left: -100%;
  }
  &__right-middle {
    box-sizing: border-box;
    padding-left: 190px;
    margin: 70px 0;
  }
  &__center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 330px;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #76DBD4;
    box-sizing: border-box;
    border-radius: 50%;
    padding: 2rem 2.5rem;
    margin: auto;
  }
  h5 {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.1;
    max-width: 200px;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.3;
    max-width: 230px;
  }
}

@media only screen and (max-width: 1279px ) {
  .chart {
    display: none;
  }
}
