//Colors Definitions


// Theme Colors
$color-white:#ffffff;
$color-black:#000000;
$color-lessBlack:#111216;
$color-fadeBlack:#4a4a4a;
$color-prGreen:#71DBD4;

$color-secGreen:#4cb5ae;
$color-lightGrey:#EBEBEB;
$color-transparent:transparent;

$color-prblue:#001437;
$color-secBlue:#282468;
$color-grOverlay:#71dbd4;
$color-blOverlay:#282367;

//Social Colors
$color-facebook:#3B5998;
$color-twitter:#55acee;
$color-instagram:#e4405f;
$color-linkedIn:#0077b5;
$color-whatsapp:#43d854;
$color-snapchat:#fffa37;
$color-youtube:#cd201f;
$color-skype:#00aff0;
$color-pinterest:#bd081c;
$color-yahoo:#410093;

$color-fbSec:#3B5998;
$color-twSec:#4099FF;
$color-instaSec:#e4405f;
$color-linSec:#0e76a8;