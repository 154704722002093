.news-list {
  margin: 2rem 0 !important;
  &__date {
    margin-bottom: 0.4rem;
  }
  &__heading {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }
  a:focus {
    color: unset;
  }
  li {
    display: block;
    cursor: pointer;
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .news-list__heading, .news-list__descr {
        color:$color-prGreen;
      }
    }
  }
  span {
    display: inline-block;
    width: 100%;
  }
}

.resources {
  margin-top: 80px;
  &__logo {
    margin-right: 40px;
  }
  &__image {
    display: flex;
    width: 200px;
    height: 100px;
    margin-bottom: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      object-fit: contain;
      margin: auto 0;
    }
  }
  a {
    color: $color-prGreen;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.8;
  }
}

.colour {
  margin-right: 25px;
  &__demo {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  &:last-child .colour__demo {
    border: 1px solid #a4a4a4;
  }
}

@media only screen and (max-width:639px ) {
  .resources {
    &__media {
      flex-direction: column;
    }
    &__logo {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 449px ) {
  .colour {
    margin-right: 15px;
    &__demo {
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-right: 0;
      .colour__demo {
        border: 1px solid #a4a4a4;
      }
    }
  }
}