.notification {
  background: #FFFFFF;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
  border-radius: 7px;
  padding: 24px;
  margin-bottom: 32px;
  h6{
    color:$color-prblue;
    font-size: 18px;
  }
  p{
    font-size: 12px;
  }
  span,a{
    color:$color-prGreen;
    cursor:pointer;
  }
  .social-icons a{
    font-size: 14px;
    padding: 0 0.2rem;
    &:nth-of-type(1){
      color: #0077B5;
    }
    &:nth-of-type(2){
      color: #3b5999;
    }
    &:nth-of-type(3){
      color: #55acee;
    }
    &:nth-of-type(4){
      color: #061437;
    }
  }
  .social-icons {
    margin: 1rem 0 0;
    padding:0;
  }
  input{
    float: left;
    width:65% !important;
    font-size: 14px;
    font-style: italic;
    height:38px;
    border-radius: 8px 0 0 8px !important;
    background: #F4F4F4;
    border:0px;
  }
  button{
    width: 35% !important;
    padding: 7px 0px;
    height: 38px;
    border-radius: 0px 8px 8px 0 !important;
    border: 0;
    margin: 0;
  }
}

@media only screen and (max-width:1120px ) {
  .notification {
    padding: 18px;
    .social-icons a {
      padding: 0rem;
    }
  }
}

@media only screen and (max-width:767px ) {
  .notification {
    margin-bottom: 12px;
  }
}
