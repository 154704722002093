@import './vendor/bootstrap', './base/bootstrap_overrides';
@import 'colors', 'mixins', 'general';
@import './components/buttons.scss';
@import './components/header.scss';
@import './components/footer.scss';
@import './components/sidebar.scss';
@import './components/bottom-text-animation.scss';
@import './components/carousel.scss';
@import './components/owl-slider.scss';
@import './components/select.scss';
@import './components/notification.scss';
@import './components/searchResults.scss';
@import './components/trust-list.scss';
@import './components/techs.scss';
@import './components/benefits.scss';
@import './components/feature-cards.scss';
@import './components/similar-jobs.scss';
@import './components/values-chart.scss';
@import './components/newsroom.scss';
@import './components/home-page.scss';

.about-page {
  &_top {
    padding-top: 220px;
  }
  &_mission {
    padding-top: 50px;
  }
  .sec-heading, p {
    max-width: 775px;
  }
  p {
    margin: 1.8rem 0;
  }
}

.details-page {
  padding-bottom: 4rem;
  p {
    margin-bottom: 2rem;
  }
}

.details-image {
  justify-content: center;
  align-items: center;
  width: 75%;
  margin-top: -2rem;
  margin-top: -25rem;
  margin-left: 3rem;
  &_right-transformed {
    margin-right: -5rem;
  }
  img {
    width: 100%;
  }
}

.brands-page {
  &__list {
    li {
      margin-bottom: 3rem;
    }
  }
  &__logo {
    width: 180px;
    margin-bottom: 2rem;
    img {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .container {
    max-width: 800px;
  }
  .sec-subheading {
    margin-bottom: 2rem;
  }
}

.tech-logos {
  text-align: center;
  margin-top: 60px;
  img{
    width: 90px;
    height: 70px;
    margin: 10px 12px;
    object-fit: contain;
  }
}

.details {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.career-banner {
  height: 40rem;
  box-sizing: border-box;
  padding-top: 12rem;
}

.newsroom-banner {
  box-sizing: border-box;
  padding: 220px 0 120px;
}

.contacts {
  margin: -200px -10px 0;
  &__item {
    padding: 10px;
  }
  .col-lg-4 {
    &:nth-of-type(4n + 1) .city {
      background-color: #DAE0FF;
    }
    &:nth-of-type(4n + 2) .city {
      background-color: #BEFFFB;
    }
    &:nth-of-type(4n + 3) .city {
      background-color: #DCD4D4;
    }
    &:nth-of-type(4n) .city {
      background-color: #FFFFFF;
    }
  }
} 

.city {
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  &__content {
    padding: 40px;
  }
  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  h5 {
    font-size: 2.125rem;
  }
  p {
    font-size: 1.125rem;
    font-weight: 400;
  }
  a {
    display: block;
    font-size: 1.125rem;
    font-weight: 500;
  }
}

.ethic-sec {
  p {
    font-size: 1rem;
    font-weight: 400;
    max-width: 370px;
  }
  a {
    font-weight: 700;
    &:hover {
      color: $color-lessBlack;
      text-decoration: underline;
    }
  }
}

.discover, .categories-list {
  margin: 3rem -10px 0;
  .col-lg-4 {
    padding: 10px;
    &:nth-of-type(4n + 1) .categories{
      background-color: #DAE0FF;
    }
    &:nth-of-type(4n + 2) .categories {
      background-color: #BEFFFB;
    }
    &:nth-of-type(4n + 3) .categories {
      background-color: #DCD4D4;
    }
    &:nth-of-type(4n) .categories {
      background-color: #FFFFFF;
    }
  }
}

.categories {
  background-color: #fff;
  height: 133px;
  padding: 13px 20px;
  border-radius: 7px;
  h5{
    font-size: 20px;
    color: #000;
    margin: 0;
  }
  span{
    font-size: 16px;
    font-weight: 500;
  }
  img{
    display: block;
    width: 24px;
    right: 20px;
    margin-left: 30px;
  }
}

.jobs-sec {
  padding-top: 2rem;
}

.jobs {
  margin-top: 48px;
  .cat-list{
    margin: 20px 0;
    &__category {
      color: $color-prGreen;
      margin-bottom: 5px;
    }
    h5 {
      font-size: 28px;
      color: $color-white;
      margin: 0;
    }
    a {
      display: inline-block;
      & span {
        font-size: 1.1rem;
        font-weight: 400;
        color: $color-prGreen;
      }
    }
  }
}

.title_desc {
  font-size: 14px;
  color:#fff;
  span:first-child{
    border-right: 2px solid #fff;
    padding-right: 10px;
    margin-right: 10px;
  }
}

.description_data {
  p {
    color: $color-prblue;
    font-size: 14px;
    font-weight: 500;
  }
  ul {
    margin-bottom: 16px !important;
  } 
  li {
    color: $color-prblue;
    position: relative;
    display: block;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 500;
    &:before{
      content: "";
      position: absolute;
      position: absolute;
      top: 9px;
      left: 0;
      box-sizing: border-box;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background: #001437;
    }
  }
  .fill-btn-green{
    width: 35%;
  }
}

.article-sec {
  &__left {
    padding-right: 60px;
    p {
      color: $color-lessBlack;
      font-weight: 400;
      & > span {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.8;
      }
    }
    a {
      color: $color-prGreen;
      &:hover {
        text-decoration: underline;
      }
    }
    blockquote {
      box-sizing: border-box;
      padding-left: 30px;
      position: relative;
      &:before {
        content: "";
        width: 5px;
        background: $color-prGreen;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 40px;
      }
      span {
        display: inline-block;
        width: 100%;
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.2;
        text-align: right;
        font-style: italic;
      }
    }
    ul li {
      color: $color-prblue;
      position: relative;
      display: block;
      padding-left: 20px;
      font-size: 1rem;
      font-weight: 400;
      &:before{
        content: "";
        position: absolute;
        top: 13px;
        left: 0;
        box-sizing: border-box;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background: #001437;
      }
    }
  }
}

.mailBox {
  border-radius: 7px;
  background: #FFFFFF;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
  width: 100%;
  padding: 32px;
  margin-top: 60px;
  h5{
    font-size: 24px;
    margin-bottom: 18px;
  }
  input{
    width: 38% !important;
    background: #F4F4F4;
    border: 0;
    font-style: italic;
    font-size: 14px;
    float: left;
    height: 38px;
  }
  .fill-btn-green {
    padding: 0.55rem 0.8rem;
    margin-top: 0;
    border-radius: 8px;
    border: 0;
    width:21%;
  }
}

.singlePage-banner header .fill-btn-green {
  display: none;
}

.not-found {
  width: 100%;
  min-height: 100vh;
  &__content, .container {
    width: 100%;
    height: 100%;
  }
  &__num {
    font-size: 300px;
    line-height: 1;
  }
}

@import 'responsive';

