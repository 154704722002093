.techs {
  margin: 2.5rem 0 0;
  &__icon {
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  ul {
    display: flex;
    margin: 0 -1rem;
  }
  li{
    width: 14.28%;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  p {
    line-height: 1.2;
  }
}

@media only screen and (max-width:1120px ) {
  .techs{
    ul {
      flex-wrap: wrap;
    }
    li{
      width: 25%;
      padding: 1rem;
    }
  }
}

@media only screen and (max-width: 639px ) {
  .techs{
    li{
      width: 100%;
      display: flex;
      padding: 0.5rem 1rem;
      img {
        width: 45px;
      }
    }
    p {
      font-size: 1.1rem;
      margin-left: 1rem;
    }
  }
}
