.similar-jobs {
  min-height: 240px;
  background: $color-white;
  padding: 24px;
  color: $color-black;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  margin: 15px 0px;
  border: 1px solid #f6f6f6d4;
  h5{
    font-size: 20px;
    color: $color-prblue;
  }
  p{
    color:#707070;
  }
  a{
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 1.4rem;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    background-color: #71DBD4;
    &:hover{
      background-color: #4cb5ae;
      color: #001437;
    }
  }
  & > span{
    color: #001437;
    font-size: 14px;
    padding: 0;
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .job-location{

    font-weight:300;
    margin-bottom: 10px;
  }
  .col, a {
    flex-shrink: 0;
  }
}

#show-all {
  cursor: pointer;
  a{
    color:$color-prGreen;
  }
}

@media only screen and (max-width: 991px ) {
  .similar-jobs {
    min-height: auto;
  }

  #relevantJobs {
    a.fr {
      padding: 0.5rem 0.6rem;
      font-size: 8px;
      float: right;
    }
  }
}

@media only screen and (max-width: 767px ) {
  #similar-jobs .text-right{
    text-align: left !important;
  }
}

@media only screen and (max-width: 480px ) {
  #similar-jobs .title{
    font-size: 24px;
  }
}

@media only screen and (max-width:414px ) {
  #relevantJobs{
    margin-top: 12px;
    .similar-jobs {
      padding: 12px;
      a{
        padding: 0.5rem 0.8rem;
      }
      p{
        font-size: 12px;
      }
      h5{
        font-size: 18px;
      }
    }
  }
}