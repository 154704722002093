.feature-cards {
  margin: 0 -0.5rem;
  &__content {
    border-radius: 0.5rem;
    height: 100%;
    padding: 1.8rem 2rem 2.5rem;
  }
  &__item {
    &:nth-of-type(3n + 1) .feature-cards__content {
      background-color: #DBE1FE;
    }
    &:nth-of-type(3n + 2) .feature-cards__content {
      background-color: #BEFFFB;
    }
    &:nth-of-type(3n + 3) .feature-cards__content {
      background-color: #DCD4D4;
    }
  }
  h5 {
    font-size: 2.13rem;
  }
}

.about-page .feature-cards p {
  margin: 0;
  line-height: 1.2;
}

@media only screen and (max-width:767px ) {
  .feature-cards {
    &__item {
      margin-bottom: 1rem;
    }
  }
}