.owl-carousel {
  .owl-item {
    img{
      width: auto !important;
    }
  }
  .owl-nav button {
    width: 25px;
    height: 30px;
  }
}

.owl-nav {
  button{
    position: absolute;
    top: 50%;
    background-color: #000;
    color: $color-white;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  button.owl-prev {
    left: 0;
  }
  button.owl-next {
    right: 0;
  }
}

.owl-dots {
  display: none;
  button.owl-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    margin: 0 3px;
  }
}

.owl-dots{
  button.owl-dot.active  {
    background-color: $color-black;
  }
  button.owl-dot:focus {
    outline: none;
  }
}

.owl-nav {
  position: absolute;
  right: 0;
  top: -50px;
  width: 70px;
  span{
    opacity: 0;
    height: 30px;
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    height: 20px;
    background: $color-transparent !important;
    i{
      color: #71dbd4;
      font-size: 30px !important;
    }
    &:focus {
      outline: none;
    }
  }
}

.owl-next.disabled {
  opacity: 0.2;
}

.owl-prev.disabled {
  opacity: 0.2;
}

.owl-slider {
  margin: 50px 0 0;
}

@media only screen and (max-width:480px ) {
  .owl-carousel .owl-nav button {
    width: 33px;
  }

  .owl-nav {
    top: -30px;
    right: 10px;
  }
}

