/*----------------sidebar-content----------------*/
.sidebar-content {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 1rem 0;
  li{
    display: block;
    padding: 10px 0;
  }
}

.sidebar-content li > a {
  color: #001437;
  font-weight: bold;
  font-size: 22px;
  padding: 10px 10px 10px 55px;
}

.sidebar-content li ul li {
  padding: 5px 10px 0 25px;
}

/*----------------------sidebar-menu-------------------------*/
.sidebar-content li > a{
  border-left: 6px solid $color-white;
}
.sidebar-menu ul>li a:hover, .sidebar-menu ul > li.active a:hover, .sidebar-menu li.active >a{
  border-left: 6px solid $color-prGreen;
  color: $color-prGreen;
}

.sidebar-menu .sidebar-submenu li a:hover {
  border-left: none !important;
}

.side-head {
  padding: 0 45px;
  margin-bottom: 50px;
}

.sidebar-menu .sidebar-submenu {
  display: none;
  li a{
    font-size:17px;
    border-left: none;
  }
}

.sidebar-dropdown > a i{
  font-size: 18px;
}

.sidebar-dropdown.active > a i{
  transform: rotate(-90deg);

}

/*------scroll bar---------------------*/
.sidebar-content::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
.sidebar-content::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.sidebar-content::-webkit-scrollbar-thumb {
  background: #4cb5ae;
  border: 0px none $color-white;
  border-radius: 0px;
}
.sidebar-content::-webkit-scrollbar-thumb:hover, .sidebar-content::-webkit-scrollbar-thumb:active  {
  background: #4cb5ae;
}
.sidebar-content::-webkit-scrollbar-track {
  background: $color-transparent;
  border: 0px none $color-white;
  border-radius: 50px;
}
.sidebar-content::-webkit-scrollbar-track:hover, .sidebar-content::-webkit-scrollbar-track:active, .sidebar-content::-webkit-scrollbar-corner {
  background: $color-transparent;
}

.lang-mbl {
  display: none !important;
  ul li a {
    padding: 0;
    border-color: transparent;
  }
}

#lgMenu {
  width: 40%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: -40%;
  overflow: hidden;
  background-color: $color-white;
  box-shadow: 5px 5px 10px #0c172e;
  position: fixed;
  z-index: 11;
  opacity: 0;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

#lgMenu.enter {
  opacity: 1;
  left: 0;
}

@media only screen and (max-width:991px ) {
  #lgMenu {
    width: 60%;
    left: -60%;
  }
}

@media only screen and (max-width:767px ) {
  .sidebar-content li > a {
    font-size: 17px;
    padding: 10px 10px 10px 20px;
  }
  
  .sidebar-dropdown > a i {
    font-size: 13px;
  }

  .sidebar-menu .sidebar-submenu li a {
    font-size: 14px;
  }

  .side-head {
    padding-left: 25px;
    margin-bottom: 30px;
  }

  .lang-mbl{
    display: block !important;
  }

  #lgMenu {
    width: 90%;
    left: -90%;
  }
}