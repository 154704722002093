.trust-list {
  margin-top: 2rem;
  &__item {
    flex: 0 0 14.28%;
    max-width: 14.28%;
    padding: 0 15px;
  }
  &__icon {
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__texts {
    margin-left: 2rem;
  }
  &__desc {
    max-width: 90%;
  }
}

@media only screen and (max-width: 1279px ) {
  .trust-list__item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 575px ) {
  .trust-list__texts {
    margin-left: 0;
  }

  .trust-list__item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
