.fill-btn-green{
  @include btn;
  background-color: $color-prGreen;
  color:$color-prblue;
  &:hover{
    background-color: $color-secGreen;
    color:$color-prblue;
  }
}

.fill-btn-light{
  @include btn;
  background-color: $color-white;
  color:$color-lessBlack;
  border: 2px solid $color-lessBlack;
  &:hover{
    background-color: $color-lessBlack;
    color:$color-white;
  }
}

.fill-btn-light-bright{
  @include btn;
  background-color: $color-white;
  color:$color-lessBlack;
  &:hover{
    background-color: $color-prGreen;
    color:$color-white;
  }
}

.outlined-btn-dark{
  @include btn;
  background-color: $color-transparent;
  color:$color-white;
  border: 2px solid $color-lessBlack;
  color:$color-lessBlack;
  &:hover{
    background-color: $color-prGreen;
    border-color:$color-prGreen;
  }
}

.outlined-btn-light{
  position: relative;
  z-index: 999;
  @include btn;
  border: 2px solid $color-prGreen;
  color: $color-white;
  &:hover{
    background-color: $color-prGreen;
    color:$color-prblue;
  }
}

.btn-primary:not(:disabled):not(.disabled):active{
  background-color: #4cb5ae;
  border-color: #4cb5ae;
}

@media only screen and (max-width:767px ) {
  .outlined-btn-dark, .outlined-btn-light, .fill-btn-light-bright, .fill-btn-light {
    width: 100%;
  }
}

@media only screen and (max-width:480px ) {
  .outlined-btn-light, .fill-btn-green, .outlined-btn-dark, .fill-btn-light-bright, .fill-btn-light {
    padding: 0.5rem 1rem;
    font-size: 12px;
  }
}
