@media only screen and (max-width: 1279px ) {
  .chart-sec {
    padding-top: 0;
  }
}

@media only screen and (max-width:1120px ) {
  .about-page {
    &_top {
      padding-top: 170px;
    }
  }

  .details-image {
    margin-top: -12rem;
    margin-left: 0;
    &_right-transformed {
      margin-right: 0;
    }
  }

  .career-banner {
    height: 35rem;
  }

  .newsroom-banner {
    padding: 160px 0 100px;
  }
}

@media only screen and (max-width:991px ) {
  .details-image {
    margin-top: -6rem;
    margin-left: 0;
  }

  .details {
    h4 {
      font-size: 36px;
    }
  }

  .career-banner {
    height: 30rem;
  }

  .categories-list {
    margin-top: 1rem;
  }

  .jobs {
    .cat-list h5 {
      font-size: 24px;
    }
  }

  .article-sec__left {
    padding-right: 15px;
    p {
      font-size: 1rem;
    }
    p > span {
      font-size: 1.2rem;
      line-height: 1.6;
    }
    blockquote span {
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  .single-right, .notification{
    margin-top: 24px;
  }
}

@media only screen and (max-width:767px ) {
  .about-page {
    &_top {
      padding-top: 100px;
    }
    p {
      margin: 1.4rem 0;
    }
  }

  .details-page {
    padding-bottom: 0;
    p {
      margin-bottom: 1rem;
    }
  }

  .details-image {
    margin: -8rem 30px 0rem;
  }

  .col-lg-6 .details-image .resize-image {
    margin: 0rem -90px 0rem;
  }


  .brands-page {
    &__list {
      li {
        margin-bottom: 2rem;
      }
    }
    .sec-subheading {
      margin-bottom: 1.4rem;
    }
  }

  .career-banner {
    height: auto;
    padding: 7rem 0 2rem;
  }

  .newsroom-banner {
    padding: 120px 0 60px;
  }

  .details {
    position: static;
    top: initial;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    h4{
      font-size: 30px;
    }
  }

  .tech-logos {
    display: none;
  }

  .jobs {
    .cat-list {
      min-height: auto;
      h5 {
        font-size: 18px;
        img{
          width: 24px;
        }
      }
    }
  }

  .notification {
    margin-bottom: 12px;
  }

  .contacts {
    margin-top: -120px;
  }

  #singleApplyBtn, .mailBox input, .mailBox .fill-btn-green {
    width: 100% !important;
  }

  #sendMail {
    width: 50% !important;
    float: right;
  }

  .fr #sendMail {
    width: 65% !important;
  }

  .city {
    &__content {
      padding: 30px;
    }
    h5 {
      font-size: 1.4rem;
    }
    p {
      font-size: 1rem;
    }
    a {
      font-size: 1rem;
    }
  }

  .not-found {
    h1 {
      font-size: 1.8rem;
      text-align: center;
    }
    &__num {
      font-size: 160px;
      line-height: 1;
    }
  }
}

@media only screen and (max-width:480px ) {
  .categories {
    height: 120px;
    padding: 10px 16px;
    h5 {
      font-size: 20px;
    }
  }

  .bgAyloLogoOnWhite:before , .bgAyloLogoOnWhite2:before{
    background-size:300%;
  }

  .categories-list > .col-lg-4 {
    padding: 5px 10px;
  }

  .cat-list h5 {
    font-size: 18px;
  }

  .mailBox {
    padding:18px;
    margin-top: 12px;
    h5{
      font-size:18px;
    }
  }

  #singleApply{
    padding: 0.7rem 3rem;
  }

  #liveSearch {
    &::placeholder {
      font-size: 10px;
    }
    &::-webkit-input-placeholder {
      font-size: 10px;
    }
    &::-moz-placeholder {
      font-size: 10px;
    }
    &::-ms-input-placeholder {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width:414px ) {
  .brands-page {
    &__logo {
      width: 140px;
      margin-bottom: 1rem;
    }
  }
}
