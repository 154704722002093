.bottom-text-sec {
  position: relative;
  padding-bottom: 280px;
  overflow: hidden;
}

.bottom-text {
  position: absolute;
  bottom: 290px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20%;
  right: 20%;
  max-width: 60%;
  margin: 0 auto;
  z-index: 9;
}

.move{
  white-space: nowrap;
  width: 3000px;
  max-width: 3000px;
  margin: 0 auto;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  position: absolute;
  top: 16%;
  left:-170%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  &.dark {
    -webkit-text-stroke-color: #12324D;
  }
  h2{
    font-family: "Ubuntu";
    font-size: 190px;
    text-transform: capitalize;
  }
}

#upper, #bottom{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 300%;
}

#bottom {
  top: -100px;
}

#lower{
  top: 150px;
  position: absolute;
  right: 0;
  height: 100%;
  width: 300%;
}

@media only screen and (min-width: 1920px){
  .bottom-text-sec {
    padding-bottom: 320px;
  }
  .bottom-text {
    bottom: 350px;
  }
  .move h2 {
    font-size: 238px;
  }
  #lower {
    top: 180px;
  }
}

@media only screen and (max-width:1120px ) {
  .bottom-text-sec {
    padding-bottom: 200px;
  }

  .bottom-text {
    bottom: 210px;
  }

  .move{
    #lower {
      top: 110px;
    }
    h2 {
      font-size: 140px;
    }
  }
}

@media only screen and (max-width:480px ) {
  .bottom-text-sec {
    padding-bottom: 140px;
  }

  .bottom-text {
    bottom: 150px;
  }
  .move h2 {
    font-size: 100px;
  }
  .move #lower {
    top: 80px;
  }
}