@mixin btn {
  padding: 0.7rem 3rem;
  margin-top: 2rem;
  display: inline-block;
  border-radius: 1.4rem;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  &:hover{
    background-color: $color-secGreen;
    color:$color-prblue;
  }
  &:focus{
    color:$color-prblue !important;
    outline:none !important;
  }
}

@mixin outlined{
  font-size: 3.125rem;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  line-height: 2.7rem;
  font-weight: bold;
}
