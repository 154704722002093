.result-outer {
  border-radius: 7px;
  overflow: hidden;
  width: 340px;
  background: #fff;
  margin-top: -1px;
  box-shadow: 0 0 5px 2px rgba(1, 1, 1, 0.18);
  position: relative;
}

#searchResult {
  width: 340px;
  max-height: 300px;
  overflow-y: scroll;
  background: $color-white;
  margin-top: -3px;
  box-shadow: 0 0 5px 2px rgba(1, 1, 1, 0.38);
  li{
    padding: 0 20px;
    border: 0 !important;
    a{
      padding: 10px 0;
      font-size: 14px;
      font-weight: 500;
      display: block;
      color: #5E5E5E;
      border-bottom: 1px solid #ccc;
      line-height: 1.2;
      &:hover{
        color: $color-prblue;
      }
      img{
        width: 1rem;
      }
    }
    &:last-child {
      a{
        font-weight: 700;
        font-size: 13px;
        color: #282468;
        text-transform: uppercase;
      }
    }
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-prGreen;
  }
}