body {
  background: $color-white !important;
  box-shadow: none;
  overflow-x: hidden;
  height: auto;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
}

h2, h3, h4, h5, h6{
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
}

h2 {
  font-size: 4.688rem;
  font-weight: bold;
}

h3 {
  font-size: 3.713rem;
  font-weight: bold;
}

h4 {
  font-size: 2.625rem;
  font-weight: bold;
}

h5 {
  font-size: 1.875rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
  font-weight: bold;
}

a, .sidebar-content li{
  color:$color-black;
  &:hover {
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    text-decoration: none;
    color: $color-prGreen;
  }
  &:focus{
    text-decoration: none;
  }
}

.container {
  max-width: 1180px;
}

.about {
  display: flex;
  flex-direction: row;
}

ul {
  padding: 0;
  margin-bottom: 0 !important;
  li {
    display: inline-block;
  }
}

p {
  color: $color-lessBlack;
  margin-bottom: 1rem;
  line-height: 1.8;
  font-size: 1.125rem;
  font-weight: 500;
  font-family: 'Montserrat';
}

.my-auto {
  height: 100%;
}

br {
  margin-bottom: 1rem;
}

section {
  padding: 6rem 0;
}

.color-white {
  color: $color-white;
}

.color-prGreen {
  color: $color-prGreen;
}

.color-prBlue {
  color:$color-prblue;
}

.color-secBlue {
  color: $color-secBlue;
}

.bgColor-prGreen {
  background-color: $color-prGreen;
}

.bgColor-prBlue {
  background-color: $color-prblue;
}

.bgAyloLogoOnWhite, .bgAyloLogoOnWhite2 {
  position: relative;
}

.bgAyloLogoOnWhite .container, .bgAyloLogoOnWhite2 .container{position:relative;}

.bgAyloLogoOnWhite:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url("../images/logos/aylo_grey.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 75%;
}


.bgAyloLogoOnWhite2 .container:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url("../images/logos/aylo_grey.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 50%;
}


.color-lessBlack {
  color: $color-lessBlack;
}

.bg-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.overlay {
  position: relative;
  &:before {
    content: "";
    background-color: #052a6b;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }
}

.full-overlay {
  background-color: $color-prblue;
  z-index: 9;
  opacity: 0.6;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.break-txt {
  white-space: pre-line;
}

.gutter{
  padding: 2rem 5rem 2rem 7rem;
  padding-left: calc((100% - 1180px)/2 + 30px);
}

.sec-heading {
  h2, h3, h4 {
    font-size: 60px;
    line-height: 1.3;
  }
  h2 span {
    color: #9B9B9B;
    font-weight: normal;
    font-size: 52px;
  }
}

.sec-subheading {
  font-size: 34px;
}

.sec-title {
  font-size: 1.5rem;
}

.minus-top {
  margin-top: -4rem;
  img{
    object-fit: cover;
    height: 100%;
  }
}

.feat-banner {
  height: 35rem;
  box-sizing: border-box;
  padding-top: 12rem;
  &_trust {
    height: 40rem;
  }
  &_position {
    height: 32rem;
  }
}

.hvr-float {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-float:hover, .hvr-float:focus, .hvr-float:active {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15%);
  transform: translateY(-15px);
}

.inline-txt{
  white-space:initial;
}

.hide-sm {
  display: inline-block;
}

.show-sm {
  display: none;
}

.py-150 {
  padding: 150px 0;
}

.py-8 {
  padding: 5rem 0;
}

.mw-75 {
  max-width: 75%;
}

.mw-80 {
  max-width: 80%;
}

.mw-85 {
  max-width: 85%;
}

.mw-90 {
  max-width: 90%;
}

.no-uppercase {
     text-transform: unset !important;
}

@media only screen and (min-width: 1920px){
  .overview {
    max-width: 1180px;
    margin: 0 auto;
  }

  .gutter{
    padding-left: 2rem;
  }
}

@media only screen and (max-width:1120px ) { 
  h3 {
    font-size: 44px;
  }

  .sec-heading h2, .sec-heading h3, .sec-heading h4 {
    font-size: 52px;
    span {
      font-size: 28px;
    }
  }

  .gutter {
    padding: 3rem 4rem 3rem 2rem;
  }

  .feat-banner {
    height: 28rem;
    padding-top: 6rem;
    &_trust {
      height: 34rem;
    }
    &_position {
      height: 24rem;
      .sec-heading h3 {
        font-size: 44px;
      }
    }
  }
}

@media only screen and (max-width:991px ) {
  section {
    padding: 4rem 0;
  }

  h3 {
    font-size: 38px;
  }

  .gutter {
    padding: 3rem 2rem 3rem 1.8rem;
  }
}

@media only screen and (max-width:767px ) {
  section {
    padding: 3rem 0;
  }

  .tech {
    padding: 20px;
  }

  h3, .sec-heading h2, .sec-heading h3, .sec-heading h4 {
    font-size: 34px;
  }

  p {
    line-height: 1.6;
    font-size: 1rem;
  }

  .sec-subheading {
    font-size: 22px;
  }

  .sec-title {
    font-size: 1rem;
  }

  .gutter {
    padding: 0;
    &.outlined{
      padding: 0 30px;
    }
  }

  .mw-75, .mw-80, .mw-85, .mw-90 {
    max-width: none;
  }

  .minus-top {
    margin-top: 1rem;
    img{
      height:auto;
    }
  }

  .resize-image {
    width: 150px !important;
    height: auto;
    margin: 0rem -100px 0rem;
  }

  .feat-banner {
    height: 22.625rem;
    &_trust {
      height: 22.625rem;
    }
    &_position {
      height: 22rem;
      .sec-heading h3 {
        font-size: 30px;
      }
    }
  }

  .hide-sm {
    display: none;
  }

  .show-sm {
    display: block;
  }

  .py-sm {
    padding: 3rem 1rem !important;
  }

  .px-sm {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  h1 svg {
    width: 100%;
  }

  .pt-sm-0 {
    padding-top:0 !important;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .about {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .my-auto {
    height: 50%;
  }

  .sec-heading{
    padding: 40px 0;
  }
}

@media only screen and (max-width:480px ) {
  .pt-5, .py-5 {
    padding-top: 1rem !important;
  }
}

@media only screen and (max-width:413px ) {
  h3, .sec-heading h3, .sec-heading h2, .sec-heading h4 {
    font-size: 26px;
  }

  .feat-banner_position {
    .sec-heading h3 {
      font-size: 24px;
    }
  }

}
