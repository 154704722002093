.masthead {
  background: $color-transparent;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 1.3rem 0;
  -webkit-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  h1 {
    display: inline-block;
    max-width: 100px;
  }
}

.burger {
  margin-right: 60px;
}

.change-color{
  fill:$color-white;
}

.masthead.sticky {
  background: $color-white;
  position: fixed;
  box-shadow: 0 0 5px #ababab;
  z-index: 999999;
  padding: 0;
  .change-color{
    fill:$color-prblue;
  }
  li svg path{
    fill:$color-prblue;
  }
  .c-lggnav{
    a{
      color: $color-prblue;
    }
  }
}

.c-lggnav {
  a{
    color: $color-white;
    font-size: 14px;
    font-weight: bold;
  }
}

.nav-right{
  li{
    margin: 0 0.75rem;
    cursor: pointer;
  }
}

header .fill-btn-green{
  margin-top: 0;
}

#close-sidebar img{
  cursor: pointer;
}

@media only screen and (max-width: 991px ) {
  .burger {
    margin-right: 30px;
  }
}

@media only screen and (max-width:767px ) {
  .nav-right li {
    margin-right: 0;
    &:last-child {
      display: none;
    }
  }

  #show-sidebar svg{
    width: 25px;
  }
}

@media only screen and (max-width: 480px ) {
  #menu svg{
    width: 22px;
  }
}

@media only screen and (max-width: 414px ) {
  .burger {
    margin-right: 20px;
  }

  header {
    h1 svg {
      width: 80px;
    }
    .fill-btn-green {
      flex-shrink: 0;
      padding: 7px 15px;
      font-size: 11px;
    }
  }
}