.hero{
  min-height: 750px;
  position: relative;
  h2{
    font-size: 3.5rem;
    line-height: 1;
  }
  h6{
    position: absolute;
    left: -8.5rem;
    top: 50%;
    bottom: 50%;
    transform: rotate(-90deg);
    -webkit-transform:rotate(-90deg);
    -moz-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    display: inline-block;
    letter-spacing: 0.3rem;
  }
  input{
    background: $color-white;
    border-radius: 7px;
    border: 0;
    height: 56px;
    font-weight: bold;
    padding: 0.5rem 1.3rem;
    font-size: 14px;
    color: black;
    margin-top: 3rem;
    width: 366px;
  }
}

.news-notification {
  display: none;
  margin-bottom: 40px;
  &.show {
    display: block;
  }
  &__title {
    img {
      display: block;
      margin-right: 10px;
    }
  }
  &__content {
    box-sizing: border-box;
    border: 4px solid #7adbd3;
    border-radius: 30px;
    background: #ffffff;
    padding: 15px;
    margin-top: 10px;
    text-align: center;
    a {
      flex: 1
    }
  }
  &__button {
    display:block;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 0;
    background: $color-prGreen;
    margin-left: 10px;
    color: $color-white;
    span {
      width: 100%;
      display: inline-block;
      font-size: 16px;
      text-align: center;
      line-height: 18px;
      margin-top: 2px;
    }
  }
}

.brands {
  img {
    max-width: 100%;
  }
}

.careers-sec {
  &__image {
    margin-top: -8rem;
    img {
      width: 100%;
    }
  }
  .sec-title {
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 2rem;
  }
}

.benefits-sec {
  &__slider {
    margin: 5rem 0 2rem;
  }
  &__slider-title {
    position: relative;
    margin-bottom: -1rem;
  }
  p {
    font-weight: 500;
  }
}

@media only screen and (max-width:1120px ) {
  .hero {
    min-height: auto;
    padding-top: 8rem;
  }
}

@media only screen and (max-width:991px ) {
  .careers-sec__image {
    margin-top: -5rem;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width:767px ) {
  .hero {
    padding-top: 7rem;
    input, #searchResult, .result-outer {
      width: 100%;
    }
    h6 {
      position: static;
      transform: rotate(0deg);
      font-size:12px;
    }
  }

  .hero input {
    margin-top: 1.5rem;
  }

  .news-notification {
    margin-bottom: 30px;
    &__content a {
      font-size: 1rem;
    }
  }

  .careers-sec {
    &__image {
      margin-top: -4rem;
      margin-bottom: 2rem;
    }
    p {
      margin-top: 1.4rem;
      font-size: 1rem;
    }
  }
}
