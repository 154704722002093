footer{
  padding-top: 4rem;
}

.social:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.social {
  -webkit-transform: scale(0.8);
  /* Browser Variations: */
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

.social-icons{
  margin: 2rem 0;
  a{
    color:$color-prblue;
    padding:0 0.5rem;
    display: inline-block;
  }
}

#social-fb:hover {
  color: $color-fbSec;
}

#social-tw:hover {
  color: $color-twSec;
}

#social-gp:hover {
  color: $color-instaSec;
}

#social-em:hover {
  color: $color-linSec;
}

.footer-nav {
  a {
    font-size: 0.9375rem;
    &:first-child{
      border-right: 2px solid $color-black;
      padding-right: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}

.copyright{
  padding: 1.1rem;
  margin-top: 5rem;
  background-color:$color-lightGrey;
  p{
    margin-bottom: 0;
    display:inline-block;
    font-size:14px;
    font-weight: normal;
    color: #4a4a4a;

  }
  .copyright-nav{
    display:inline-block;
    a{
      font-size: 14px;
      color: $color-fadeBlack;
      padding: 0 8px;
      border-right: 1px solid $color-fadeBlack;
      &:hover{
        color: $color-prGreen;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
}

footer .fill-btn-green {
  display: none;
}

@media only screen and (max-width:767px ) {
  .copyright {
    display: none;
  }
  footer .fill-btn-green {
    display: inline-block;
  }
}
